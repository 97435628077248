<template>
	<div>
		<section>
			<div style="margin-top: 25px; margin-bottom: 15px">
				<span class="title" style="color: red">Customer: {{ customer }} </span>
			</div>
		</section>
		<section>
			<div style="margin-top: 25px; margin-bottom: 15px">
				<span class="title">Customer Consumed Products</span>
			</div>
			<textarea
				class="textarea"
				placeholder="Enter customer consumed products"
				readonly
				rows="5"
				v-model="editorContentCustomerConsumedProducts"
			></textarea>
		</section>
		<section>
			<div>
				<div style="margin-top: 25px; margin-bottom: 15px">
					<span class="title">Customer Logs</span>
				</div>
				<textarea
					class="textarea"
					placeholder="Enter the notes about the customer"
					readonly
					rows="5"
					v-model="editorContentCustomerLog"
				></textarea>
			</div>
		</section>
		<section>
			<b-button
				class="width-250"
				style="
					background-color: rgb(32, 156, 238);
					color: white;
					margin-top: 35px;
				"
				@click="onEdit"
			>
				Edit Customer Information
			</b-button>
		</section>
		<div v-if="isAddCustomerInfoModalVisible">
			<AddCustomerInfoModal />
		</div>
	</div>
</template>

<script>
import AddCustomerInfoModal from '@/_srcv2/pages/business-development/customer-info/add-customer-info/components/AddCustomerInfoModal.vue'
import useCustomerInfoState from '@/_srcv2/pages/business-development/customer-info/scripts/useCustomerInfoState.js'
import GetCustomerLogsAndProductsQuery from '@/_srcv2/pages/business-development/customer-info/graphql/GetCustomerLogsAndProductsQuery.graphql'
import { useQuery } from '@vue/apollo-composable'
import { ref, reactive } from '@vue/composition-api'
import Store from '@/store'

export default {
	name: 'GetCustomersLogsAndProductsSubComponent',
	components: {
		AddCustomerInfoModal,
	},
	setup() {
		const {
			isAddCustomerInfoModalVisible,
			editorContentCustomerConsumedProducts,
			editorContentCustomerLog,
			customerID,
			customer,
		} = useCustomerInfoState()
		const onEdit = () => {
			isAddCustomerInfoModalVisible.value = true
		}
		// todo -----------------------------------------------------------
		// ? ----------------------------------------------------------------
		const queryVariables = ref({
			company_id: Store.getters.getUserCurrentCompany,
			customer_id: customerID,
		})
		// ? ----------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult, onError } = useQuery(
			GetCustomerLogsAndProductsQuery,
			() => queryVariables.value,
			options,
		)
		onResult((result) => {
			console.log('------>>> result', result?.data?.customers)
			const customerInfo = result?.data?.customers[0]
			editorContentCustomerLog.value = customerInfo?.customer_log
			editorContentCustomerConsumedProducts.value =
				customerInfo?.customer_consumed_products
		})
		onError((error) => {
			console.log('error', error)
		})
		// todo -----------------------------------------------------------
		return {
			onEdit,
			isAddCustomerInfoModalVisible,
			editorContentCustomerConsumedProducts,
			editorContentCustomerLog,
			customerID,
			customer,
		}
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 1.3rem;
	font-weight: 700;
	color: #0b10a8d9;
}
</style>
