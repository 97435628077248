<template>
	<div>
		<div style="margin-top: 50px; margin-bottom: 15px">
			<span class="title">Customer Visiting Notes</span>
		</div>
		<section>
			<b-table
				:data="rows"
				:columns="columns"
				striped
				paginated
				per-page="5"
			></b-table>
		</section>
	</div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import GetCustomerVisitsNotesQuery from '@/_srcv2/pages/business-development/customer-info/graphql/GetCustomerVisitsNotes.graphql'
import useCustomerInfoState from '@/_srcv2/pages/business-development/customer-info/scripts/useCustomerInfoState.js'
import Store from '@/store'
export default {
	name: 'GetCustomerVisitsNotes',
	components: {},
	setup() {
		// todo -----------------------------------------------------------
		const { customerID } = useCustomerInfoState()
		const rows = ref([])
		// todo ----------------------------------------------------------------
		const queryVariables = ref({
			company_id: Store.getters.getUserCurrentCompany,
			customer_id: customerID,
		})
		// ? ----------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult, onError } = useQuery(
			GetCustomerVisitsNotesQuery,
			() => queryVariables.value,
			options,
		)
		onResult((result) => {
			rows.value = result?.data?.customers_visits.map((item) => {
				return {
					visit_date: item.visit_date,
					visit_notes: item.visit_notes,
					is_customer_visited: item.is_customer_visited,
				}
			})
			console.log(
				'------>>> GetCustomerVisitsNotesQuery result',
				result?.data?.customers_visits,
			)
		})
		onError((error) => {
			console.log('error', error)
		})
		const columns = reactive([
			{
				field: 'visit_date',
				label: 'Visit Date',
				width: '130',
			},
			{
				field: 'visit_notes',
				label: 'Visit Notes',
			},
			{
				field: 'is_customer_visited',
				label: 'Is Visited',
				width: '130',
			},
		])
		// todo -----------------------------------------------------------
		return {
			columns,
			rows,
		}
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 1.3rem;
	font-weight: 700;
	color: #0b10a8d9;
}
</style>
