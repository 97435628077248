<template>
	<LayoutWide>
		<sub-nav-bar page="Marketing" />
		<div>
			<HeroBox :title="title" :subtitle="subtitle" :color="color" />
		</div>
		<section v-if="isCustomerInfoMarketingModulesVisible">
			<b-button
				class="width-250"
				style="
					background-color: rgb(32, 156, 238);
					color: white;
					margin-top: 35px;
				"
				@click="onMakeNewSearch"
			>
				Make New Search
			</b-button>
		</section>
		<section v-if="isSearchVisible">
			<GetAllVisitableCustomerSearchComponent />
		</section>
		<section v-if="isCustomerInfoMarketingModulesVisible">
			<div>
				<GetCustomersLogsAndProductsSubComponent
					:key="keyCustomerInfoMarketingModules"
				/>
			</div>
		</section>
		<section v-if="isCustomerInfoMarketingModulesVisible">
			<div>
				<GetCustomerVisitsNotes :key="keyCustomerInfoMarketingModules" />
			</div>
		</section>
	</LayoutWide>
</template>

<script>
import SubNavBar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import { ref, onMounted, computed } from '@vue/composition-api'
import useCustomerInfoState from '@/_srcv2/pages/business-development/customer-info/scripts/useCustomerInfoState.js'
import GetAllVisitableCustomerSearchComponent from '@/_srcv2/pages/business-development/customer-info/add-customer-info/components/GetAllVisitableCustomerSearchComponent.vue'
import GetCustomersLogsAndProductsSubComponent from '@/_srcv2/pages/business-development/customer-info/get-customer-marketing-info/components/GetCustomersLogsAndProductsSubComponent.vue'
import GetCustomerVisitsNotes from '@/_srcv2/pages/business-development/customer-info/get-customer-marketing-info/components/GetCustomerVisitsNotes.vue'

export default {
	name: 'GetCustomerMarketingInfo',
	components: {
		SubNavBar,
		HeroBox,
		GetAllVisitableCustomerSearchComponent,
		GetCustomersLogsAndProductsSubComponent,
		GetCustomerVisitsNotes,
	},
	setup() {
		const title = ref('Customer Marketing Information')
		const subtitle = ref(
			'You can get customer marketing information such as logs , product list that customer consumes.',
		)
		const color = ref('is-info')
		// todo -----------------------------------------------------------
		const {
			isGetAllVisitableCustomerSearchComponentVisible,
			isCustomerInfoMarketingModulesVisible,
			keyCustomerInfoMarketingModules,
			GetAllVisitableCustomerSearchComponentType,
			editorContentCustomerConsumedProducts,
			editorContentCustomerLog,
			companyID,
			customerID,
			customer,
		} = useCustomerInfoState()
		const isSearchVisible = computed(() => {
			return isGetAllVisitableCustomerSearchComponentVisible.value
		})
		// ? ------------------------------------------------------------
		onMounted(() => {
			console.log('GetCustomerMarketingInfo is onMounted')
			GetAllVisitableCustomerSearchComponentType.value = 'ShowMarketingInfo'
			isCustomerInfoMarketingModulesVisible.value = false
			editorContentCustomerConsumedProducts.value = ''
			editorContentCustomerLog.value = ''
			companyID.value = ''
			customerID.value = ''
			customer.value = ''
		})
		// todo -----------------------------------------------------------
		const onMakeNewSearch = () => {
			isGetAllVisitableCustomerSearchComponentVisible.value = true
			isCustomerInfoMarketingModulesVisible.value = false
		}
		// todo -----------------------------------------------------------
		return {
			title,
			subtitle,
			color,
			isSearchVisible,
			isCustomerInfoMarketingModulesVisible,
			keyCustomerInfoMarketingModules,
			onMakeNewSearch,
		}
	},
}
</script>

<style lang="scss" scoped></style>
